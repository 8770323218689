<template>
	<div class="text">
		<div class="text-header">
			<img class="header-logo" :src="require('../../assets/icons/logo-half.svg')" />
			<div class="header-title">{{ t('Privacy.title') }}</div>
		</div>
		<div class="text-body">
			<p>INSHOP.gg adopts this Privacy Policy which describes how it collects and processes the information about the User collected through the Website. Please, do not use the Website if you do not agree to the Privacy Policy.</p>
			<h1>1. Terms and definition</h1>
			<p>
				1.1.
				<br />The following terms used in this Privacy Policy have the meanings set forth in this paragraph:
				<br />«Personal account» – Account maintained by the User for the purposes of trading through inshop.gg.
				<br />«Personal Data» –Any data relating to a living individual who is or can be identified either from the data or from the data in conjunction with other information that is in, or is likely to come into, the possession of inshop.gg.
				<br />«Privacy Policy» –The present document.
				<br />«Processing» – Any operation or set of operations which is performed upon personal data, whether or not by automatic means, such as collection, recording, organization, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, blocking, erasure or destruction.
				<br />«Terms of Use» – An agreement between the User and inshop.gg available at https://inshop.gg/tos/
				<br />«Traffic Data» – The information of the User’s IP addresses, domain servers, types of computers accessing the Website and types of web browsers used to access the Website.
				<br />«User» – An individual who uses the Website under Terms of Use.
				<br />«Website» – Collection of information, texts, graphic elements, design, pictures, photo and video and other intellectual property, as well as the software in the information system available on the Internet at a domain address https://inshop.gg/.
			</p>
			<p>
				1.2.
				The headings (of articles) of the Privacy Policy are for convenience only and shall not in any way affect the meaning or interpretation of the Privacy Policy.
			</p>
			<p>
				1.3.
				The Privacy Policy shall be governed and construed in accordance with the laws of inshop.gg domicile, unless otherwise expressly provided.
			</p>
			<h1>2. Collected Information</h1>
			<p>
				2.1.
				inshop.gg may collect the following information:
			</p>
			<p>
				2.1.1.
				Traffic Data;
			</p>
			<p>
				2.1.2.
				Personal Data;
			</p>
			<p>
				2.2.
				Traffic Data is not Personal Data, however, inshop.gg uses cookies or similar technologies that may to some extend identify the User. Cookies are small text files that enable inshop.gg to collect the information about web-browsing activities of inshop.gg such as the pages visited by the User and the time the User spent on viewing the page. The User may decline the cookies at his or her discretion in the personal settings of the web-browser.
			</p>
			<p>
				2.3.
				inshop.gg collects Personal Data that the User provides directly under the Terms of Use to perform any transactions at inshop.gg or use inshop.gg services. Personal Data may include:
			</p>
			<p>
				2.3.1.
				Contact information, such as name, home address and email address;
			</p>
			<p>
				2.3.2.
				Financial information, such as bank account numbers, bank statement, and trading information;
			</p>
			<p>
				2.3.3.
				Identity verification information, such as images of your government issued ID;
			</p>
			<p>
				2.3.4.
				Residence verification information.
			</p>
			<h1>3. The Purposes of Processing</h1>
			<p>
				3.1.
				inshop.gg uses the Personal Data in order to:
			</p>
			<p>
				3.1.1.
				Process the User’s transactions;
			</p>
			<p>
				3.1.2.
				Respond to the User’s service requests and support needs;
			</p>
			<p>
				3.1.3.
				Contact you about inshop.gg ’s news, updates, promotions and new services (marketing communications). If you wish to stop receiving marketing communications from us, please contact us at ceo@сrystalfuture.com to opt-out.
			</p>
			<p>
				3.2.
				inshop.gg uses Traffic Data in order to:
			</p>
			<p>
				3.2.1.
				Understand traffic patterns and the number of visitors to the Website;
			</p>
			<p>
				3.2.2.
				Understand how the User uses and interacts with the Website;
			</p>
			<p>
				3.2.3.
				Improve the Website;
			</p>
			<p>
				3.2.4.
				Build up statistical data for auditing, research, modeling, and reporting for our advertisers and other partners;
			</p>
			<p>
				3.2.5.
				Provide the User relevant advertising and content.
			</p>
			<h1>4. Security and Transfer of Personal Data to Third Parties</h1>
			<p>
				4.1.
				inshop.gg reserves the right to store the information, including Personal Data collected under para. 2.3 of the Privacy Policy, within the period up to 5 years. inshop.gg uses standard adequate security measures against loss, misuse or alteration of such information. However, inshop.gg cannot guarantee the total invulnerability of the security system from illegal actions of the third parties and the User shall not address any claim in relation to any damages caused by illegal actions of the third parties.
			</p>
			<p>
				4.2.
				The User agrees that inshop.gg may share and transfer information, including Personal Data collected under para. 2.3 of the Privacy Policy, to the third party who is a contracting party with both inshop.gg and the User. This third party may have its own policies and practices of Processing. At any event inshop.gg is not responsible for such policies or practices of any third party.
			</p>
			<p>
				4.3.
				Whether inshop.gg assigns its rights to the third party or is involved in a merger or similar transfer of assets, the User grants to inshop.gg the right to the transfer the Personal Data to its assignee, acquiring company or transferee of the assets.
			</p>
			<h1>5. Changes to the Privacy Policy</h1>
			<p>
				5.1.
				inshop.gg reserves the right to add to, change, update, or modify the Privacy Policy. Any such change, update, or modification will be effective immediately upon posting on the Website and the User acknowledges that the ongoing use of the Website is independent and adequate consideration in support of such modification. If any modification is unacceptable, the User’s only recourse is not to use the Website.
			</p>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	setup() {
		const { t } = useI18n()

		return {
			t
		}
	}
})
</script>
<style scoped>
@import url("../../assets/styles/text.css");
</style>
